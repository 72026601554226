
.mySwitch .check::after {
    background: #7a0a0a !important;
    border-color: #ffffff !important;
}
.mySwitch input[type="checkbox"]:checked~.check {
    background: #d3d3d3 !important;
}
.mySwitch input[type="checkbox"]:checked~.check::after {
    border-color: #ffffff !important;
    background: #380bff !important;
}
