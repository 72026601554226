
.tree-item-header[data-v-0d18c21f] {
    
    align-items: left;
    cursor: pointer;
}
button[data-v-0d18c21f] {
    margin-left: auto;
    margin-right: 5px;
}
.tree-item[data-v-0d18c21f] {
  transition: background-color 0.3s ease;
}
.tree-item.hovered[data-v-0d18c21f] {
  background-color: #f0f0f0;
}
.tree-item.selected-item[data-v-0d18c21f] {
  background-color: #d3d3d3; /* Or any color you prefer */
}
  