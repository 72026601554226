
.input .append[data-v-fb1d02d8] {
    min-width: 0px;
}
@media screen and (min-width: 640px) {
.login-box[data-v-fb1d02d8] {
        width: 360px;
}
}
.login-box[data-v-fb1d02d8] {
    width: 360px;
    display: block;
    position: relative;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
.login-form[data-v-fb1d02d8] {
    width: 350px;
    height: auto;
    top: 50%;
}
.avatar[data-v-fb1d02d8] {
    width: 128px;
    height: auto;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid white;
}